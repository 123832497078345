* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#map-box {
  width: 100%;
  height: calc(100vh - 100px);
}

#finder-logo {
  height: 50px;
  margin: 6px 0 0 0;
}

.header {
  height: 64px;
  padding: 0 20px;
  line-height: 64px;
  background: #001529;
}

.message {
  color: greenyellow;
  float: right;
  text-transform: capitalize;
}

.info {
  color: white;
  background-color: #5f3dc4;
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
}

.info-box {
  margin: 0 20px;
}

.ignition-on {
  color: #51cf66;
}

.ignition-off {
  color: #fa5252;
}

@media only screen and (max-width: 600px) {
  .message {
    float: none;
    font-size: 12px;
    line-height: 2.4;
  }

  .info {
    justify-content: center;
    font-size: 14px;
  }

  .info-box {
    display: block;
    margin: 4px;
  }
}

